/*
Holds all key/value pairs used by otovoweb's Unleash client.

Are you introducing a new toggle in the code? Add a key/value pair here and
you can start to use it via the `isEnabled(...)` function.
*/

module.exports = {
  ADD_CONSUMPTION_FEATURE_NAME: 'WEB-add-consumption',
  GOOGLE_MAPS_ENABLED: 'WEB-google-maps-sales-flow',
  SANITY_SUBSIDY_NAME: 'WEB-sanity-subsidy',
  NEWSLETTER_CONSENT_FEATURE_NAME: 'WEB-newsletter-consent',
  LEASING_SUBSIDY_HIDDEN: 'WEB-leasing-subsidy-hidden',
  ENABLE_CLOUD_DEBUG_TOOL_NAME: 'WEB-enable-cloud-debug-tool',
  SUPPORT_ARTICLES_ENABLED: 'WEB-support-articles-enabled',
  GLOBAL_ALERT: 'WEB-global-alert',
  POST_ACTIVATION_FORM_ENABLED_FEATURE_NAME: 'WEB-post-activation-form-enabled',
  BATTERY_TOGGLE_IN_SAVINGS: 'WEB-battery-toggle-savings-calc-page',
  FREE_TEXT_ADDRESS_SEARCH_ENABLED: 'free-text-address-search-enabled',
  SHOW_OR_SIMILAR_STANDARD_PANEL_TEXT:
    'WEB-show-or-similar-standard-panel-text',
  SHOW_OR_SIMILAR_PREMIUM_PANEL_TEXT: 'WEB-show-or-similar-premium-panel-text',
  SHOW_OR_SIMILAR_TIER3_PANEL_TEXT: 'WEB-show-or-similar-tier3-panel-text',
  SHOW_OR_SIMILAR_INVERTER_TEXT: 'WEB-show-or-similar-inverter-text',
  SHOW_OR_SIMILAR_BATTERY_TEXT: 'WEB-show-or-similar-battery-text',
  SHOW_OR_SIMILAR_SMART_METER_TEXT: 'WEB-show-or-similar-smart-meter-text',
  EV_CHARGER_INFO_CALC_PAGE: 'WEB-show-ev-charger-info-on-calc-page',
  HEAT_PUMPS_INFO_CALC_PAGE: 'WEB-show-heat-pumps-info-on-calc-page',
  REFERRAL_REDESIGN: 'WEB-referral-redesign',
  SHOW_REPAYMENT_PLAN_START_BALANCE: 'WEB-show-repayment-plan-start-balance',
  SHOW_REPRESENTATIVE_EXAMPLES_ON_CALC_PAGE:
    'WEB-show-representative-examples-on-calc-page',
  SHOW_APR_AND_CASH_PRICE_LEASING_HEADER:
    'WEB-show-apr-and-cash-price-leasing-header',
  SHOW_APR_AND_CASH_PRICE_LEASING_PRICE_SECTION:
    'WEB-show-apr-and-cash-price-leasing-price-section',
};
