import theme from '../../rainbow/theme';
import {
  createKeyframeAnimation,
  createSlideInDownAnimationString,
} from './keyframeUtils';

const { colors } = theme;

const SLIDE_IN_DOWN_ANIMATION_DURATION = 3.6;
const SLIDE_IN_ANIMATION_DELAY = 0.6;

export const slideInDownCTAAnimation: string = createSlideInDownAnimationString(
  [
    { '0%': { background: colors.digital, color: 'white' } },
    { '78%': { background: colors.digital, color: 'white' } },
    { '100%': { background: colors.lilac_5, color: colors.grey_60 } },
  ],
  `${SLIDE_IN_DOWN_ANIMATION_DURATION}s cubic-bezier(0.42, 0, 0.58, 1) ${SLIDE_IN_ANIMATION_DELAY}s forwards`,
);

export const slideInDownSuccessAnimation: string =
  createSlideInDownAnimationString(
    [
      { '0%': { background: colors.green_10, color: colors.green_100 } },
      { '78%': { background: colors.green_10, color: colors.green_100 } },
      { '100%': { background: colors.lilac_5, color: colors.grey_60 } },
    ],
    `${SLIDE_IN_DOWN_ANIMATION_DURATION}s cubic-bezier(0.42, 0, 0.58, 1) ${SLIDE_IN_ANIMATION_DELAY}s forwards`,
  );

export const slideInDownErrorAnimation: string =
  createSlideInDownAnimationString(
    [
      { '0%': { background: colors.red_10, color: colors.red_100 } },
      { '78%': { background: colors.red_10, color: colors.grey_60 } },
      { '100%': { background: colors.lilac_5, color: colors.grey_60 } },
    ],
    `${SLIDE_IN_DOWN_ANIMATION_DURATION}s cubic-bezier(0.42, 0, 0.58, 1) ${SLIDE_IN_ANIMATION_DELAY}s forwards`,
  );

export const slideOutUpAnimation: string = createKeyframeAnimation(
  [
    {
      '0%': {
        transform: 'translateY(0)',
        background: colors.lilac_5,
        color: colors.grey_60,
        opacity: 1,
      },
    },
    {
      '100%': {
        transform: 'translateY(-100%)',
        background: colors.lilac_5,
        color: colors.grey_60,
        opacity: 1,
      },
    },
  ],
  '0.4s cubic-bezier(0.42, 0, 0.58, 1) forwards',
);

/**
 * The link animation need to wait for the slide down animation to be done
 * before starting.
 */

const linkTransitionTime = SLIDE_IN_DOWN_ANIMATION_DURATION * (1 - 0.78); // matches keyframes between 78%-100% in the slide down animation – approx 0.8s
const linkDelay =
  SLIDE_IN_DOWN_ANIMATION_DURATION -
  linkTransitionTime +
  SLIDE_IN_ANIMATION_DELAY;

export const whiteToDefaultLink: string = createKeyframeAnimation(
  [{ '0%': { color: 'white' } }, { '100%': { color: colors.digital } }],
  `${linkTransitionTime}s cubic-bezier(0.42, 0, 0.58, 1) ${linkDelay}s forwards`,
);
