import getFirstFromArray from '../../../utils/getFirstFromArray';
import storage from '../../localStorage';

import { UTMKeys, utmKeyMapping } from '../utmConfig';
import referrerParser from './referrerParser';

const UTM_STORAGE_KEY = 'utm_data';
const DOCUMENT_REFERRER_KEY = 'referrer'; // The Document.referrer property returns the URI of the page that linked to this page.

export type UtmData = {
  [k in UTMKeys | 'referrer']?: string;
};

type AcceptedQueryKeys = UTMKeys;
type Query = {
  [k in AcceptedQueryKeys]?: string | string[];
};

function storeUtmQueryParamsToLocalStorage(query: Query) {
  const expiry = Date.now() + 24 * 3600 * 1000; // In 24 hours

  const utmData = {} as UtmData;

  Object.keys(query).forEach((key) => {
    const matchedKey = Object.keys(utmKeyMapping).find((utmKey) =>
      utmKeyMapping[utmKey].includes(key),
    );
    if (matchedKey) {
      utmData[matchedKey] = getFirstFromArray(query[key]);
    }
  });

  // Remove old utmData if it changes
  if (Object.keys(utmData).length) {
    storage.removeItem(UTM_STORAGE_KEY);
  }

  if (Object.keys(utmData).length) {
    storage.setItem(UTM_STORAGE_KEY, utmData, expiry);
  }

  if (__isBrowser__) {
    const referrer = referrerParser(document.referrer);
    if (referrer) {
      storage.setItem(DOCUMENT_REFERRER_KEY, referrer, expiry);
    }
  }
}

function retrieveUtmQueryParamsFromLocalStorage(): UtmData {
  const utmData = storage.getItem<UtmData | null>(UTM_STORAGE_KEY) || {};
  const referrer = storage.getItem<string | null>(DOCUMENT_REFERRER_KEY);

  if (!Object.keys(utmData).length && referrer) {
    // Fallback using referrer in case we don't have any UTM data. Not sure who
    // uses this, but we have 500k+ interests in Cloud with this utm_data so I
    // don't dare to remove it.
    return {
      utm_medium: DOCUMENT_REFERRER_KEY,
      utm_source: referrer,
    };
  }

  if (referrer) {
    // Inside an <iframe>, the Document.referrer will initially be set
    // to the same value as the href of the parent window's Window.location.
    utmData.referrer = referrer;
  }

  return utmData;
}

function clearUtmQueryParamsFromLocalStorage() {
  storage.removeItem(UTM_STORAGE_KEY);
  storage.removeItem(DOCUMENT_REFERRER_KEY);
}

const UtmLocalStorage = {
  store: storeUtmQueryParamsToLocalStorage,
  retrieve: retrieveUtmQueryParamsFromLocalStorage,
  clear: clearUtmQueryParamsFromLocalStorage,
};

export default UtmLocalStorage;
